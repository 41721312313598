<script setup>
import SearchWidget from '@/Components/Search/Widget.vue';
import LayoutSearch from '@/Layouts/Search.vue';
import CourseListItem from '@/Components/List/Item/Course.vue';
import PaginatedList from '@/Components/List/Paginated.vue';
import { ref, computed } from 'vue';
import Heading from '@/Components/Heading.vue';
import { Head } from '@inertiajs/vue3';

const props = defineProps({
  courses: {
    type: Object,
    required: true
  },
  queryObject: {
    type: Object,
    required: true,
  }
});

const activeTab = ref('courses');

const tuitionBracket = computed(() => {
  if(!props.queryObject.tuition_fee) {
    return null
  }

  const tuition = decodeURIComponent(props.queryObject.tuition_fee).split(',');
  return {
    min: tuition[0],
    max: tuition[1],
  };
});

</script>

<template>
  <Head>
    <title>Search Courses</title>
    <meta name="description" content="Course Search Results - Study Australia">
  </Head>
  <LayoutSearch>
    <template #hero>
      <SearchWidget v-model="activeTab" :query-object="queryObject"/>
    </template>
    <template #content>
      <div v-if="activeTab != 'courses'">
        <div class="py-32">
          <Heading
            class="text-center"
            size="h2"
            style-size="h1"
          >
            Start searching {{ activeTab }}
          </Heading>
        </div>
      </div>
      <PaginatedList
        v-else
        slug="courses"
        list-name="courses"
        :current-page="courses.meta.page"
        :total-results="courses.meta.total"
        :query-object="queryObject"
      >
        <CourseListItem
          v-for="course, index in courses.data"
          :key="`course-${index}`"
          :course="course"
          :tuition-bracket="tuitionBracket"
        />
      </PaginatedList>
    </template>
  </LayoutSearch>
</template>

